@use "../styles/variables.scss" as sdv;

.flex {
  display: flex;
  flex: 1;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fd-column {
  flex-direction: column;
}

.fd-row {
  flex-direction: row;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.js-start {
  justify-self: flex-start;
}

.js-center {
  justify-self: center;
}

.js-end {
  justify-self: flex-end;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ai-stretch {
  align-items: stretch;
}

.ai-baseline {
  align-items: baseline;
}

.as-start {
  align-self: flex-start;
}

.as-center {
  align-self: center;
}

.as-end {
  align-self: flex-end;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-nowrap {
  flex-wrap: nowrap;
}

.gap-4 {
  gap: sdv.$spacing-4;
}

.gap-8 {
  gap: sdv.$spacing-8;
}

.gap-12 {
  gap: sdv.$spacing-12;
}

.gap-16 {
  gap: sdv.$spacing-16;
}

.gap-20 {
  gap: sdv.$spacing-20;
}

.gap-24 {
  gap: sdv.$spacing-24;
}

.gap-32 {
  gap: sdv.$spacing-32;
}

.gap-40 {
  gap: sdv.$spacing-40;
}

.f-grow-1 {
  flex-grow: 1;
}

.f-grow-2 {
  flex-grow: 2;
}

.f-grow-3 {
  flex-grow: 3;
}

.f-grow-4 {
  flex-grow: 4;
}
