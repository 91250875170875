@import "./dimensions";

@mixin mobile-s {
  @media (min-width: $screen-mobile-s) {
    @content;
  }
}

@mixin mobile-m {
  @media (min-width: $screen-mobile-m) {
    @content;
  }
}

@mixin mobile-l {
  @media (min-width: $screen-mobile-l) {
    @content;
  }
}

@mixin mobile-xl {
  @media (min-width: $screen-mobile-xl) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-tablet) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $screen-laptop) {
    @content;
  }
}

@mixin laptop-m {
  @media (min-width: $screen-laptop-m) {
    @content;
  }
}

@mixin laptop-l {
  @media (min-width: $screen-laptop-l) {
    @content;
  }
}

@mixin laptop-xl {
  @media (min-width: $screen-laptop-xl) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-desktop) {
    @content;
  }
}
