/* You can add global styles to this file, and also import other style files */

@use "./styles/variables.scss" as var;
@use "./styles/mixins.scss" as mixins;
@use "./styles/media.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* Box-sizing border-box */
* {
  color: var.$color-base-black;
  box-sizing: border-box;
}

.text-ellipsis {
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.mat-icon {
  @include mixins.elumity-icon-default;
}

.custom-overlay-container {
  height: 100dvh;
  width: 100vw;
  color: var.$color-base-white;
  background: rgba(var.$color-base-black, 0.7);

  .close-icon-container {
    width: 100%;
    margin-top: var.$spacing-32;
    margin-bottom: var.$spacing-32;

    .mat-icon {
      margin-right: var.$spacing-32;
      cursor: pointer;
      user-select: none;
      @include mixins.elumity-icon-large;
    }
  }
}

.layout-container {
  max-width: var.$max-content-width;
  margin: 0 auto;

  padding: 0 var.$spacing-20;

  @include media.laptop {
    padding: 0 var.$spacing-32;
  }
}

.mdc-tooltip__surface.mdc-tooltip__surface-animation {
  background-color: var.$color-base-black !important;
}

.mdc-button__label {
  color: var.$color-base-white;
}

p-galleria {
  width: 100vw;
  height: 100dvh;
}

p-galleriacontent {
  width: 100% !important;
  @include media.tablet {
    max-width: 800px;
    width: 50% !important;
  }

  .p-galleria-content {
    .p-galleria-item {
      height: 50dvh !important;
    }

    .p-galleria-thumbnail-wrapper {
      display: none !important;
      @include media.tablet {
        display: flex !important;
      }
    }
  }

  .p-galleria-close {
    position: absolute;

    .p-icon-wrapper .p-icon path {
      color: var.$color-base-white !important;
    }
  }
}
