@use "../styles/variables.scss" as sdv;

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: sdv.$spacing-4 !important;
}

.m-8 {
  margin: sdv.$spacing-8 !important;
}

.m-12 {
  margin: sdv.$spacing-12 !important;
}

.m-16 {
  margin: sdv.$spacing-16 !important;
}

.m-20 {
  margin: sdv.$spacing-20 !important;
}

.m-24 {
  margin: sdv.$spacing-24 !important;
}

.m-32 {
  margin: sdv.$spacing-32 !important;
}

.m-40 {
  margin: sdv.$spacing-40 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: sdv.$spacing-4 !important;
}

.mt-8 {
  margin-top: sdv.$spacing-8 !important;
}

.mt-12 {
  margin-top: sdv.$spacing-12 !important;
}

.mt-16 {
  margin-top: sdv.$spacing-16 !important;
}

.mt-20 {
  margin-top: sdv.$spacing-20 !important;
}

.mt-24 {
  margin-top: sdv.$spacing-24 !important;
}

.mt-32 {
  margin-top: sdv.$spacing-32 !important;
}

.mt-40 {
  margin-top: sdv.$spacing-40 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: sdv.$spacing-4 !important;
}

.mb-8 {
  margin-bottom: sdv.$spacing-8 !important;
}

.mb-12 {
  margin-bottom: sdv.$spacing-12 !important;
}

.mb-16 {
  margin-bottom: sdv.$spacing-16 !important;
}

.mb-20 {
  margin-bottom: sdv.$spacing-20 !important;
}

.mb-24 {
  margin-bottom: sdv.$spacing-24 !important;
}

.mb-32 {
  margin-bottom: sdv.$spacing-32 !important;
}

.mb-40 {
  margin-bottom: sdv.$spacing-40 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-4 {
  margin-right: sdv.$spacing-4 !important;
}

.mr-8 {
  margin-right: sdv.$spacing-8 !important;
}

.mr-12 {
  margin-right: sdv.$spacing-12 !important;
}

.mr-16 {
  margin-right: sdv.$spacing-16 !important;
}

.mr-20 {
  margin-right: sdv.$spacing-20 !important;
}

.mr-24 {
  margin-right: sdv.$spacing-24 !important;
}

.mr-32 {
  margin-right: sdv.$spacing-32 !important;
}

.mr-40 {
  margin-right: sdv.$spacing-40 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-4 {
  margin-left: sdv.$spacing-4 !important;
}

.ml-8 {
  margin-left: sdv.$spacing-8 !important;
}

.ml-12 {
  margin-left: sdv.$spacing-12 !important;
}

.ml-16 {
  margin-left: sdv.$spacing-16 !important;
}

.ml-20 {
  margin-left: sdv.$spacing-20 !important;
}

.ml-24 {
  margin-left: sdv.$spacing-24 !important;
}

.ml-32 {
  margin-left: sdv.$spacing-32 !important;
}

.ml-40 {
  margin-left: sdv.$spacing-40 !important;
}

.mrl-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mrl-4 {
  margin-right: sdv.$spacing-4 !important;
  margin-left: sdv.$spacing-4 !important;
}

.mrl-8 {
  margin-right: sdv.$spacing-8 !important;
  margin-left: sdv.$spacing-8 !important;
}

.mrl-12 {
  margin-right: sdv.$spacing-12 !important;
  margin-left: sdv.$spacing-12 !important;
}

.mrl-16 {
  margin-right: sdv.$spacing-16 !important;
  margin-left: sdv.$spacing-16 !important;
}

.mrl-20 {
  margin-right: sdv.$spacing-20 !important;
  margin-left: sdv.$spacing-20 !important;
}

.mrl-24 {
  margin-right: sdv.$spacing-24 !important;
  margin-left: sdv.$spacing-24 !important;
}

.mrl-32 {
  margin-right: sdv.$spacing-32 !important;
  margin-left: sdv.$spacing-32 !important;
}

.mrl-40 {
  margin-right: sdv.$spacing-40 !important;
  margin-left: sdv.$spacing-40 !important;
}

.mtb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mtb-4 {
  margin-top: sdv.$spacing-4 !important;
  margin-bottom: sdv.$spacing-4 !important;
}

.mtb-8 {
  margin-top: sdv.$spacing-8 !important;
  margin-bottom: sdv.$spacing-8 !important;
}

.mtb-12 {
  margin-top: sdv.$spacing-12 !important;
  margin-bottom: sdv.$spacing-12 !important;
}

.mtb-16 {
  margin-top: sdv.$spacing-16 !important;
  margin-bottom: sdv.$spacing-16 !important;
}

.mtb-20 {
  margin-top: sdv.$spacing-20 !important;
  margin-bottom: sdv.$spacing-20 !important;
}

.mtb-24 {
  margin-top: sdv.$spacing-24 !important;
  margin-bottom: sdv.$spacing-24 !important;
}

.mtb-32 {
  margin-top: sdv.$spacing-32 !important;
  margin-bottom: sdv.$spacing-32 !important;
}

.mtb-40 {
  margin-top: sdv.$spacing-40 !important;
  margin-bottom: sdv.$spacing-40 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: sdv.$spacing-4 !important;
}

.p-8 {
  padding: sdv.$spacing-8 !important;
}

.p-12 {
  padding: sdv.$spacing-12 !important;
}

.p-16 {
  padding: sdv.$spacing-16 !important;
}

.p-20 {
  padding: sdv.$spacing-20 !important;
}

.p-24 {
  padding: sdv.$spacing-24 !important;
}

.p-32 {
  padding: sdv.$spacing-32 !important;
}

.p-40 {
  padding: sdv.$spacing-40 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-4 {
  padding-top: sdv.$spacing-4 !important;
}

.pt-8 {
  padding-top: sdv.$spacing-8 !important;
}

.pt-12 {
  padding-top: sdv.$spacing-12 !important;
}

.pt-16 {
  padding-top: sdv.$spacing-16 !important;
}

.pt-20 {
  padding-top: sdv.$spacing-20 !important;
}

.pt-24 {
  padding-top: sdv.$spacing-24 !important;
}

.pt-32 {
  padding-top: sdv.$spacing-32 !important;
}

.pt-40 {
  padding-top: sdv.$spacing-40 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-4 {
  padding-bottom: sdv.$spacing-4 !important;
}

.pb-8 {
  padding-bottom: sdv.$spacing-8 !important;
}

.pb-12 {
  padding-bottom: sdv.$spacing-12 !important;
}

.pb-16 {
  padding-bottom: sdv.$spacing-16 !important;
}

.pb-20 {
  padding-bottom: sdv.$spacing-20 !important;
}

.pb-24 {
  padding-bottom: sdv.$spacing-24 !important;
}

.pb-32 {
  padding-bottom: sdv.$spacing-32 !important;
}

.pb-40 {
  padding-bottom: sdv.$spacing-40 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-4 {
  padding-right: sdv.$spacing-4 !important;
}

.pr-8 {
  padding-right: sdv.$spacing-8 !important;
}

.pr-12 {
  padding-right: sdv.$spacing-12 !important;
}

.pr-16 {
  padding-right: sdv.$spacing-16 !important;
}

.pr-20 {
  padding-right: sdv.$spacing-20 !important;
}

.pr-24 {
  padding-right: sdv.$spacing-24 !important;
}

.pr-32 {
  padding-right: sdv.$spacing-32 !important;
}

.pr-40 {
  padding-right: sdv.$spacing-40 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-4 {
  padding-left: sdv.$spacing-4 !important;
}

.pl-8 {
  padding-left: sdv.$spacing-8 !important;
}

.pl-12 {
  padding-left: sdv.$spacing-12 !important;
}

.pl-16 {
  padding-left: sdv.$spacing-16 !important;
}

.pl-20 {
  padding-left: sdv.$spacing-20 !important;
}

.pl-24 {
  padding-left: sdv.$spacing-24 !important;
}

.pl-32 {
  padding-left: sdv.$spacing-32 !important;
}

.pl-40 {
  padding-left: sdv.$spacing-40 !important;
}

.prl-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.prl-4 {
  padding-right: sdv.$spacing-4 !important;
  padding-left: sdv.$spacing-4 !important;
}

.prl-8 {
  padding-right: sdv.$spacing-8 !important;
  padding-left: sdv.$spacing-8 !important;
}

.prl-12 {
  padding-right: sdv.$spacing-12 !important;
  padding-left: sdv.$spacing-12 !important;
}

.prl-16 {
  padding-right: sdv.$spacing-16 !important;
  padding-left: sdv.$spacing-16 !important;
}

.prl-20 {
  padding-right: sdv.$spacing-20 !important;
  padding-left: sdv.$spacing-20 !important;
}

.prl-24 {
  padding-right: sdv.$spacing-24 !important;
  padding-left: sdv.$spacing-24 !important;
}

.prl-32 {
  padding-right: sdv.$spacing-32 !important;
  padding-left: sdv.$spacing-32 !important;
}

.prl-40 {
  padding-right: sdv.$spacing-40 !important;
  padding-left: sdv.$spacing-40 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ptb-4 {
  padding-top: sdv.$spacing-4 !important;
  padding-bottom: sdv.$spacing-4 !important;
}

.ptb-8 {
  padding-top: sdv.$spacing-8 !important;
  padding-bottom: sdv.$spacing-8 !important;
}

.ptb-12 {
  padding-top: sdv.$spacing-12 !important;
  padding-bottom: sdv.$spacing-12 !important;
}

.ptb-16 {
  padding-top: sdv.$spacing-16 !important;
  padding-bottom: sdv.$spacing-16 !important;
}

.ptb-20 {
  padding-top: sdv.$spacing-20 !important;
  padding-bottom: sdv.$spacing-20 !important;
}

.ptb-24 {
  padding-top: sdv.$spacing-24 !important;
  padding-bottom: sdv.$spacing-24 !important;
}

.ptb-32 {
  padding-top: sdv.$spacing-32 !important;
  padding-bottom: sdv.$spacing-32 !important;
}

.ptb-40 {
  padding-top: sdv.$spacing-40 !important;
  padding-bottom: sdv.$spacing-40 !important;
}
