@use "./typography.scss" as var;
@use "./dimensions.scss" as dimensions;

@mixin typography-rules-setter(
  $font-size,
  $text-decoration,
  $font-weight,
  $font-style,
  $font-stretch,
  $letter-spacing,
  $paragraph-indent,
  $text-case,
  $line-height: 1
) {
  font-size: $font-size !important;
  text-decoration: $text-decoration !important;
  font-weight: $font-weight !important;
  font-style: $font-style !important;
  font-stretch: $font-stretch !important;
  letter-spacing: $letter-spacing !important;
  text-indent: $paragraph-indent !important;
  text-transform: $text-case !important;
  line-height: $line-height !important;
}

@mixin typography-mobile-heading-1-mixin(
  $line-height: var.$typography-mobile-heading-h1-line-height
) {
  @include typography-rules-setter(
    var.$typography-mobile-heading-h1-font-size,
    var.$typography-mobile-heading-h1-text-decoration,
    var.$typography-mobile-heading-h1-font-weight,
    var.$typography-mobile-heading-h1-font-style,
    var.$typography-mobile-heading-h1-font-stretch,
    var.$typography-mobile-heading-h1-letter-spacing,
    var.$typography-mobile-heading-h1-paragraph-indent,
    var.$typography-mobile-heading-h1-text-case,
    $line-height
  );
}

@mixin typography-heading-1-mixin(
  $line-height: var.$typography-heading-h1-line-height
) {
  @include typography-rules-setter(
    var.$typography-heading-h1-font-size,
    var.$typography-heading-h1-text-decoration,
    var.$typography-heading-h1-font-weight,
    var.$typography-heading-h1-font-style,
    var.$typography-heading-h1-font-stretch,
    var.$typography-heading-h1-letter-spacing,
    var.$typography-heading-h1-paragraph-indent,
    var.$typography-heading-h1-text-case,
    $line-height
  );
}

@mixin typography-mobile-heading-2-mixin(
  $line-height: var.$typography-mobile-heading-h2-line-height
) {
  @include typography-rules-setter(
    var.$typography-mobile-heading-h2-font-size,
    var.$typography-mobile-heading-h2-text-decoration,
    var.$typography-mobile-heading-h2-font-weight,
    var.$typography-mobile-heading-h2-font-style,
    var.$typography-mobile-heading-h2-font-stretch,
    var.$typography-mobile-heading-h2-letter-spacing,
    var.$typography-mobile-heading-h2-paragraph-indent,
    var.$typography-mobile-heading-h2-text-case,
    $line-height
  );
}

@mixin typography-heading-2-mixin(
  $line-height: var.$typography-heading-h2-line-height
) {
  @include typography-rules-setter(
    var.$typography-heading-h2-font-size,
    var.$typography-heading-h2-text-decoration,
    var.$typography-heading-h2-font-weight,
    var.$typography-heading-h2-font-style,
    var.$typography-heading-h2-font-stretch,
    var.$typography-heading-h2-letter-spacing,
    var.$typography-heading-h2-paragraph-indent,
    var.$typography-heading-h2-text-case,
    $line-height
  );
}

@mixin typography-mobile-heading-3-mixin(
  $line-height: var.$typography-mobile-heading-h3-line-height
) {
  @include typography-rules-setter(
    var.$typography-mobile-heading-h3-font-size,
    var.$typography-mobile-heading-h3-text-decoration,
    var.$typography-mobile-heading-h3-font-weight,
    var.$typography-mobile-heading-h3-font-style,
    var.$typography-mobile-heading-h3-font-stretch,
    var.$typography-mobile-heading-h3-letter-spacing,
    var.$typography-mobile-heading-h3-paragraph-indent,
    var.$typography-mobile-heading-h3-text-case,
    $line-height
  );
}

@mixin typography-heading-3-mixin(
  $line-height: var.$typography-heading-h2-line-height
) {
  @include typography-rules-setter(
    var.$typography-heading-h3-font-size,
    var.$typography-heading-h3-text-decoration,
    var.$typography-heading-h3-font-weight,
    var.$typography-heading-h3-font-style,
    var.$typography-heading-h3-font-stretch,
    var.$typography-heading-h3-letter-spacing,
    var.$typography-heading-h3-paragraph-indent,
    var.$typography-heading-h3-text-case,
    $line-height
  );
}

@mixin typography-body-default-mixin(
  $line-height: var.$typography-body-default-line-height
) {
  @include typography-rules-setter(
    var.$typography-body-default-font-size,
    var.$typography-body-default-text-decoration,
    var.$typography-body-default-font-weight,
    var.$typography-body-default-font-style,
    var.$typography-body-default-font-stretch,
    var.$typography-body-default-letter-spacing,
    var.$typography-body-default-paragraph-indent,
    var.$typography-body-default-text-case,
    $line-height
  );
}

@mixin typography-body-small-regular-mixin(
  $line-height: var.$typography-body-small-regular-line-height
) {
  @include typography-rules-setter(
    var.$typography-body-small-regular-font-size,
    var.$typography-body-small-regular-text-decoration,
    var.$typography-body-small-regular-font-weight,
    var.$typography-body-small-regular-font-style,
    var.$typography-body-small-regular-font-stretch,
    var.$typography-body-small-regular-letter-spacing,
    var.$typography-body-small-regular-paragraph-indent,
    var.$typography-body-small-regular-text-case,
    $line-height
  );
}

@mixin typography-body-medium-regular-mixin(
  $line-height: var.$typography-body-medium-regular-line-height
) {
  @include typography-rules-setter(
    var.$typography-body-medium-regular-font-size,
    var.$typography-body-medium-regular-text-decoration,
    var.$typography-body-medium-regular-font-weight,
    var.$typography-body-medium-regular-font-style,
    var.$typography-body-medium-regular-font-stretch,
    var.$typography-body-medium-regular-letter-spacing,
    var.$typography-body-medium-regular-paragraph-indent,
    var.$typography-body-medium-regular-text-case,
    $line-height
  );
}

@mixin typography-body-medium-semibold-mixin(
  $line-height: var.$typography-body-medium-semibold-line-height
) {
  @include typography-rules-setter(
    var.$typography-body-medium-semibold-font-size,
    var.$typography-body-medium-semibold-text-decoration,
    var.$typography-body-medium-semibold-font-weight,
    var.$typography-body-medium-semibold-font-style,
    var.$typography-body-medium-semibold-font-stretch,
    var.$typography-body-medium-semibold-letter-spacing,
    var.$typography-body-medium-semibold-paragraph-indent,
    var.$typography-body-medium-semibold-text-case,
    $line-height
  );
}

@mixin typography-drawer-item-mixin(
  $line-height: var.$typography-drawer-item-line-height
) {
  @include typography-rules-setter(
    var.$typography-drawer-item-font-size,
    var.$typography-drawer-item-text-decoration,
    var.$typography-drawer-item-font-weight,
    var.$typography-drawer-item-font-style,
    var.$typography-drawer-item-font-stretch,
    var.$typography-drawer-item-letter-spacing,
    var.$typography-drawer-item-paragraph-indent,
    var.$typography-drawer-item-text-case,
    $line-height
  );
}

@mixin typography-mobile-avatar-initials-mixin(
  $line-height: var.$typography-mobile-avatar-initials-line-height
) {
  @include typography-rules-setter(
    var.$typography-mobile-avatar-initials-font-size,
    var.$typography-mobile-avatar-initials-text-decoration,
    var.$typography-mobile-avatar-initials-font-weight,
    var.$typography-mobile-avatar-initials-font-style,
    var.$typography-mobile-avatar-initials-font-stretch,
    var.$typography-mobile-avatar-initials-letter-spacing,
    var.$typography-mobile-avatar-initials-paragraph-indent,
    var.$typography-mobile-avatar-initials-text-case,
    $line-height
  );
}

@mixin typography-avatar-initials-mixin(
  $line-height: var.$typography-mobile-avatar-initials-line-height
) {
  @include typography-rules-setter(
    var.$typography-avatar-initials-font-size,
    var.$typography-avatar-initials-text-decoration,
    var.$typography-avatar-initials-font-weight,
    var.$typography-avatar-initials-font-style,
    var.$typography-avatar-initials-font-stretch,
    var.$typography-avatar-initials-letter-spacing,
    var.$typography-avatar-initials-paragraph-indent,
    var.$typography-avatar-initials-text-case,
    $line-height
  );
}

/* Icon mixins */

@mixin elumity-icon-rule-setter($size, $weight) {
  width: $size;
  min-width: $size;
  height: $size;
  font-size: $size;
  font-weight: $weight;
}

@mixin elumity-icon-extra-small($weight: 400) {
  @include elumity-icon-rule-setter(12px, $weight);
}

@mixin elumity-icon-small($weight: 400) {
  @include elumity-icon-rule-setter(16px, $weight);
}

@mixin elumity-icon-medium($weight: 400) {
  @include elumity-icon-rule-setter(20px, $weight);
}

@mixin elumity-icon-default($weight: 400) {
  @include elumity-icon-rule-setter(24px, $weight);
}

@mixin elumity-icon-large($weight: 400) {
  @include elumity-icon-rule-setter(28px, $weight);
}
