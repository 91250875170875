@use "mixins" as mixins;
@use "variables" as var;
@use "media";

body,
input,
textarea,
input::placeholder,
textarea::placeholder {
  @include mixins.typography-body-default-mixin(normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  @include mixins.typography-mobile-heading-1-mixin;

  @include media.tablet {
    @include mixins.typography-heading-1-mixin;
  }
}

h2 {
  @include mixins.typography-mobile-heading-2-mixin;
  margin-bottom: var.$spacing-16 !important;

  @include media.tablet {
    @include mixins.typography-heading-2-mixin;
  }
}

h3 {
  @include mixins.typography-mobile-heading-3-mixin;
  margin-bottom: var.$spacing-12 !important;

  @include media.tablet {
    @include mixins.typography-heading-3-mixin;
  }
}

.typography-body-default {
  @include mixins.typography-body-default-mixin;
}

p {
  @include mixins.typography-body-medium-regular-mixin;
}

.elumity-icon-extra-small,
.elumity-icon-extra-small-bold {
  @include mixins.elumity-icon-extra-small;
  &.mat-icon {
    @include mixins.elumity-icon-extra-small;
  }
}

.elumity-icon-small,
.elumity-icon-small-bold {
  @include mixins.elumity-icon-small;
  &.mat-icon {
    @include mixins.elumity-icon-small;
  }
}

.elumity-icon-medium,
.elumity-icon-medium-bold {
  @include mixins.elumity-icon-medium;
  &.mat-icon {
    @include mixins.elumity-icon-medium;
  }
}

.elumity-icon-default,
.elumity-icon-default-bold {
  @include mixins.elumity-icon-default;
  &.mat-icon {
    @include mixins.elumity-icon-default;
  }
}

.elumity-icon-large,
.elumity-icon-large-bold {
  @include mixins.elumity-icon-large;
  &.mat-icon {
    @include mixins.elumity-icon-large;
  }
}

.elumity-icon-extra-small-bold,
.elumity-icon-small-bold,
.elumity-icon-medium-bold,
.elumity-icon-default-bold,
.elumity-icon-large-bold {
  font-weight: 700;
  &.mat-icon {
    font-weight: 700;
  }
}
