@import "./dimensions.scss";
@import "./media.scss";

$max-content-width: 1440px;

$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-64: 64px;

$color-base-white: rgb(255, 255, 255);
$color-base-black: rgb(30, 41, 59);
$color-base-black-fade: rgb(30, 41, 59, 0.7);
$color-border: rgb(199, 199, 199);
$color-background: rgb(222, 222, 222, 0.2);
$color-open: rgb(118, 210, 64);
